<template>
    <div class="connector-card" :style="{ position: 'relative' }">
        <div class="connector-card-content" :class="{ active: isConnectorSelected }">
            <!--  <v-row align="center"> -->
            <div v-if="project.billingEnabled && !currentTariff" class="alert-icon">
                <v-img v-if="project.billingEnabled && !currentTariff" :src="dangerIcon" />
            </div>
            <v-avatar v-if="!loading" :color="connectorAvatar.color" size="42" class="mx-2 my-2">
                <v-img v-if="connector.image" transition="slide-x-transition" :src="connector.image" />
                <span v-else>{{ connectorAvatar.symbol }}</span>
            </v-avatar>
            <v-progress-circular v-else indeterminate color="black" class="mx-2 my-3"></v-progress-circular>
            <div class="connector-card-name-wrapper">
                <h2 :title="connectorAvatar.name">{{ connectorAvatar.name }}</h2>
            </div>
            <span v-if="connector.isDraft"> (Draft)</span>
            <v-spacer></v-spacer>
            <v-menu :close-on-content-click="true" offset-x offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        medium
                        v-bind="attrs"
                        v-on="on"
                        v-if="checkRole(connector.projectId, 'project:connector:edit')"
                        :disabled="!checkRole(connector.projectId, 'project:connector:edit')"
                    >
                        <v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                </template>

                <v-card min-width="198px">
                    <v-list>
                        <v-list-item
                            class="text-btn-wrapper"
                            @click="finishConnectorCreate"
                            v-if="connector.isDraft && checkRole(project.id, 'project:connector:edit')"
                        >
                            <span class="mx-3 my-2">Завершить подключение</span>
                        </v-list-item>
                        <v-list-item
                            class="text-btn-wrapper"
                            @click="openConnectorSettings"
                            v-if="!connector.isDraft && checkRole(project.id, 'project:connector:edit')"
                        >
                            <span class="mx-3 my-2">Настройки</span>
                        </v-list-item>

                        <v-list-item
                            class="text-btn-wrapper"
                            @click="deleteConnector"
                            v-if="checkRole(project.id, 'project:connector:delete')"
                        >
                            <span class="mx-3 my-2">Удалить</span>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
            <!--   </v-row> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { checkAccess } from '@/utils'
import { checkRole } from '@root/src/helpers/roles'
import { computed } from 'vue'
import { useBillingStore } from '@root/src/store/stores/billing'
import { useProject } from '@root/src/hooks/useProject'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import api from '@root/src/api/api'
export default {
    name: 'connectorCard',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        connector: {
            type: Object,
            default: () => {},
        },
        selectedConnectors: {
            type: Array,
            default: () => [],
        },
    },
    components: {},
    data: () => ({
        connectorAvatar: {},
    }),
    computed: {
        ...mapGetters(['connectorAvatarByType']),
        project() {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        isConnectorSelected() {
            return this.selectedConnectors.includes(this.connector.connectorId)
        },
        dangerIcon() {
            return require('@/assets/danger-icon.svg')
        },
    },
    setup(props, context) {
        const { project } = useProject()
        const billingStore = computed(() => useBillingStore())
        const currentTariff = computed(() => billingStore.value.getCurrentTariff(project.value.id))
        return {
            currentTariff,
            project,
        }
    },
    methods: {
        checkAccess,
        checkRole,
        deleteConnector() {
            this.$parent.$emit('deleteConnector', this.connector.connectorId)
        },
        openConnectorSettings() {
            this.$parent.$emit('openConnectorSettings', this.connector.connectorId)
        },
        finishConnectorCreate() {
            this.$store.commit('project/setLoggedAddingConnector', this.connector)
            this.$store.commit('project/updateCurrentAddingConnector', this.connector)
            this.$router.push(`/project/${this.connector.projectId}/addConnectorStage3?complete=true`)
        },
    },

    async created() {
        if (this.connector.connectorType === CONNECTOR_TYPES.whatsappBasis) {
            const [app, error] = await api.networkFixer
                .getApp({ connectorId: this.connector.connectorId })
                .then(res => [res.data.data, res.error])
            if (app.channel?.['wApi-telegram']?.credentials) {
                this.connectorAvatar = {
                    color: '#229ED9',
                    symbol: 'Tg',
                    name: 'Telegram',
                }
            } else this.connectorAvatar = this.connectorAvatarByType(this.connector.connectorType)
        } else {
            this.connectorAvatar = this.connectorAvatarByType(this.connector.connectorType) || {
                color: 'white',
                symbol: '404',
                name: 'Не найдено',
            }
        }
    },
    mounted() {},
}
</script>

<style lang="sass" scoped>
.active
    background-color: #0000001F
.connector-card
    width: 328px
    border: 1px solid #0000001F
    border-radius: 4px
.connector-card-name-wrapper
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    max-width: 175px
.connector-card-content
    padding-left: 16px
    padding-right: 16px
    padding-top: 7px
    padding-bottom: 7px
    display: flex
    flex-direction: row
    align-items: center
button:focus
    outline: 0
.alert-icon
    width: 12px
    height: 12px
    min-width: 12px
    min-height: 12px
    border-radius: 50%
    position: absolute
    right: 6px
    top: 6px
    display: flex
    justify-content: center
    align-items: center
</style>
